import * as React from "react";
import { Component } from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import Content from "../../layout/Content/Content";
import Headline from "../../texts/Headline";

const ContentExtended = Styled(Content)`

  `;

const Container = Styled.div`
  position: relative;
  margin: 10rem 0 6rem 0;

  ${Media.greaterThan("medium")`
    margin: 15rem 0 12rem 0;
  `}
`;

const HeadlineExtended = Styled(Headline)`
  font-size: 3.5rem;

  ${Media.lessThan("medium")`
    font-size: 2.5rem;
  `}
`;

const Blogs = Styled.div`
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1em;
  grid-template-areas:
    "a a b "
    "a a c ";
  width: 100%;
  padding: auto;

  ${Media.lessThan("medium")`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

const BlogHighlight = Styled.div`
text-align: center;
  width: 100%;
  position: relative;

  &:nth-child(1) {
    grid-area: a;
    
  }
  &:nth-child(2) {
    grid-area: b;
  }
  &:nth-child(3) {
    grid-area: c;
  }

  ${Media.lessThan("medium")`
    width: 100%;
    margin: 2.5rem auto 0 auto;
  `}
`;

const Link = Styled.a`
  &:hover, &:focus {
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
  }
`;

const Image = Styled.img`
  display: block;
  width: 100%;
  height: auto;

  ${Media.greaterThan("medium")`
    height: 100%;
  `}
`;

const ImageContainer = Styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);

  &:after {
    content: 'Read more';
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
    padding-top: 25%;
    align-content: center;
    font-size: 2rem;
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    background: rgba(0,0,0,0.45);
    opacity: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  &:hover:after {
    opacity: 1;
  }
`;

type BlogProps = {
  title: string;
  blogs: Array<{
    image: string;
    link: string;
    alt: string;
  }>;
};


const BlogHighlightCondensed = ({ title, blogs }: BlogProps) => {
  return (
    <Container>
      <ContentExtended>
        <HeadlineExtended>{title}</HeadlineExtended>
        <Blogs>
          {blogs.map((blog) => (
            <BlogHighlight>
              <Link href={blog.link} target="_blank">
                <ImageContainer>
                  <Image src={blog.image} alt={blog.alt} />
                </ImageContainer>
              </Link>
            </BlogHighlight>
          ))}
        </Blogs>
      </ContentExtended>
    </Container>
  )
}

export default BlogHighlightCondensed