import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";
import * as React from "react";
import Styled from "styled-components/macro";
import Button from "../../components/buttons/Button";
import LandingPageSection, { LandingPageProps } from "../../components/LandingPageSection";
import Content from "../../components/layout/Content/Content";
import Layout from "../../components/layout/Layout";
import Meta from "../../components/meta";
import BlogHighlightCondensed from "../../components/pages/blog/BlogHighlightCondensed";
import Shoutout from "../../components/pages/signup/Shoutout";
import FourCards from "../../components/screenshot/FourCards";
import SectionWithLeftVideoDark from "../../components/SectionWithLeftVideoDark";
import Description from "../../components/texts/Description";
import Headline from "../../components/texts/Headline";
import RichDescriptionExtended from "../../components/texts/RichDescription";

import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/blog_guide_to_ai_features.png";
import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/Uizard-announces-autodesigner.png";
import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/blog_convert_screenshots.png";
import SectionWithRightImage from "../../components/pages/solutions/SectionWithRightImage";
import YoutubeEmbed from "../../components/layout/YoutubeEmbed/YoutubeEmbed";


const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;
const Column = Styled.div`
  position: relative;
  
  ${Media.lessThan("small")`
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.lessThan("medium")`
    width: 50%;
    
    &:first-of-type {
      margin-right: 3rem;
    }
    
    &:last-of-type {
      width: 32rem;
    }
    `}
   
  ${Media.lessThan("660px")`
    width: 100%;
    margin: 0 auto;
    
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.greaterThan("medium")`
    width: 45%;
    min-width: 0;
    padding: 0 3rem;
  `}

  ${Media.greaterThan("large")`
    padding: 0 6rem;
  `}
`;

const ContentIntro = Styled(Content)`
    margin-bottom: 10rem;
    
    ${Media.greaterThan("medium")`
      margin-bottom: 0;
  `}
`;

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 16rem 0 10rem 0;
  padding: 9rem;
  
  ${Media.lessThan("large")`
    padding: 0 4rem;
  `}
  
  ${Media.lessThan("medium")`
      margin: 11rem 0 5rem 0;
  `}
  
  ${Media.lessThan("small")`
    padding: 0;
    margin: 9rem 0 4rem 0;
  `}
`;

const ExtendedDescription = Styled(Description)`
    max-width: 120rem;
    margin: auto;
`;


const screenshotIndexPage = ({ data }) => {
  return (
    <>
      <Layout type="dark">
        <Meta
          title="Uizard Screenshot | Convert Screenshots Into Mockups | Uizard"
          description="Transform screenshots of apps, websites, and user interfaces into customizable mockups with Uizard Screenshot. Sign up today for free."
          url="/screenshot/" />

        <LandingPageSection
          headline="Uizard Screenshot"
          imageA={data.hifiConversionA.childImageSharp}
          imageB={data.hifiConversionB.childImageSharp}
          description="Upload screenshots of apps or web pages and transform them into customizable mockups in seconds." 
          videoName="ai-landing-screenshot"
          imageAlt="how a user can use Uizard to go from a screenshot to a design mockup"/>

        <ContentIntro>
          <Container>
            <Headline>
              Convert screenshots into editable UI designs
            </Headline>
            <RichDescriptionExtended dark content={"Convert screenshots into UI designs rapidly with Uizard Screenshot. Simply take a snap of an app or website and let Uizard do the rest. Uizard's <a:https://uizard.io/ai-design/>AI design</a> features are here to transform the way you think about design."} />
          </Container>
        </ContentIntro>

        <SectionWithRightImage
          headline="From screenshot to UI design"
          description="Upload screenshots of a website, app, or UI design and iterate with ease.
      Uizard Screenshot enables you to transform images into customizable UI components, giving you the power to iterate on your ideas like never before."
          imageA={data.screenshotA.childImageSharp}
          imageB={data.screenshotB.childImageSharp}
          imageAlt="how a user can use Uizard to go from a rough wireframe to a design mockup and a polished design"
        />
        
        <SectionWithLeftVideoDark
          headline="Advance your design"
          description="Iterate your designs with Uizard's fast and powerful drag-and-drop editor to really bring your vision to life.
      Update text, add new images, create user journeys from one screen to the next - with Uizard's <a:https://uizard.io/ai-design/>AI design</a> features, you can even import the themes of other apps with the click of a button."
          imageA={data.modularA.childImageSharp}
          imageB={data.modularA.childImageSharp}
          videoName="screenshot-landing-modular-build"
          imageAlt="how a user can use Uizard's modular desig to enable user friendly designs"
          link=""
        />

        <YoutubeEmbed
          videoW={1220}
          videoH={686}
          url="https://www.youtube.com/embed/Ybq3VGMix6o"
        />

        <BlogHighlightCondensed
          title="Learn more about AI design"
          blogs={[
            {
              image: blogThumbnail1,
              link: "https://uizard.io/blog/uizard-screenshot-convert-screenshots-to-mockups/",
              alt: "Thumbnail for blog titled Uizard Screenshot: Convert screenshots to editable mockups with AI",
            },
            {
              image: blogThumbnail2,
              link: "https://uizard.io/blog/uizard-announces-autodesigner-worlds-first-ai-ui-generator/",
              alt: "Thumbnail for blog titled Uizard announces Autodesigner: The world's first AI UI generator",
            },
            {
              image: blogThumbnail3,
              link: "https://uizard.io/blog/a-complete-guide-to-uizards-ai-features/",
              alt: "Thumbnail for blog titled A complete guide to Uizard's AI features",
            },
          ]}
        />
        <ContentExtended>
          <Shoutout
            dark
            headline="AI Design is here"
            description="Bring your ideas to life at the speed of light with Uizard's AI UI designer"
            cta="Sign up for free"
            imageA={data.shoutoutImageA.childImageSharp} />
        </ContentExtended>
      </Layout>
    </>
  )

};

export default screenshotIndexPage


export const data = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hifiConversionA: file(
      relativePath: { eq: "screenshot/hifiA.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }


    hifiConversionB: file(
      relativePath: { eq: "screenshot/hifiB.png" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }


    screenshotA: file(relativePath: { eq: "screenshot/ScreenshotToDesignA.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    screenshotB: file(relativePath: { eq: "screenshot/ScreenshotToDesignB.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    modularA: file(relativePath: { eq: "screenshot/ModularBuild.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

  }
`;
